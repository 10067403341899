<template>
  <div v-if="!isLoading">
    <RouterView />
    <EulaModal v-if="userStore.currentCustomer?.has_unaccapted_eula"></EulaModal>
  </div>
  <div v-else>
    <div v-if="couldNotInitialize" class="d-flex align-items-center justify-content-center" style="height: 100vh">
      <div class="alert alert-danger" role="alert" style="width: 400px">
        <h1 class="mb-3">Error</h1>
        {{ couldNotInitialize }}
      </div>
    </div>
    <div v-else class="d-flex flex-column">
      <div class="page page-center">
        <div class="container py-5 w-25">
          <div class="text-center">
            <div class="mb-3">
              <img src="@/assets/logo/lighthouse1_free.png" height="260" alt="" />
            </div>
            <div class="text-secondary mb-3">{{ $t('app.init_loading') }}</div>
            <div class="progress progress-sm">
              <div class="progress-bar progress-bar-indeterminate"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, provide, ref } from 'vue';
import { RouterView, useRoute, useRouter } from 'vue-router';
import { createResetPasswordSession, destroySession } from '@/api/client';
import EulaModal from '@/components/user/EulaModal.vue';

import translator, { availableLanguages, currentLanguage } from '@/locale/translator';
import { useUserStore } from '@/stores/user';
import { useCustomerFeaturesStore } from '@/stores/customerFeatures';
import { useToast } from 'vue-toastification';

export type AppLocale = {
  key: string;
  languageLabel: string;
  countryLabel: string;
};

/* ----------------------------- STATE ----------------------------- */
const toast = useToast();
const currentLanguageData = availableLanguages[currentLanguage];

const isLoading = ref(true);
const couldNotInitialize = ref<string | undefined>(undefined);

const userStore = useUserStore();
const featureStore = useCustomerFeaturesStore();
const route = useRoute();
const router = useRouter();

provide<AppLocale>('locale', {
  key: currentLanguage,
  languageLabel: currentLanguageData.label,
  countryLabel: currentLanguageData.country,
});

/* ----------------------------- METHODS ----------------------------- */

async function handleResetPassword() {
  // Reset password handler
  if (route.name !== 'resetPassword') return true;

  const { email, reset_token } = route.query as Record<string, string>;
  if (email && reset_token) {
    return await createResetPasswordSession({ email, reset_token })
      .then((res) => {
        if (!res.data?.id) return true;
        router.push({
          name: 'settings.accounts.detail',
          params: { id: res.data?.id },
          query: { password: 1 },
        });
        return false;
      })
      .catch(() => {
        return true;
      });
  }
  toast.error(translator.get('reset_password.wrong_link'));
  return true;
}

async function fetchCurrentUserAndEnforceLogin() {
  try {
    await userStore.fetchCurrent();

    if (userStore.hasValidSession === false) {
      destroySession();

      setTimeout(() => {
        window.location.reload();
      }, 1000);

      return;
    }

    if (!userStore.currentUser?.id && route.name !== 'login') {
      const redirectToLogin = await handleResetPassword();
      if (redirectToLogin) router.push({ name: 'login' });
    }

    if (userStore.currentUser?.id && ['login', 'resetPassword'].includes(route.name as string)) {
      router.push({ name: 'home' });
    }

    if (userStore.currentUser?.id) await featureStore.fetchFeatures();

    isLoading.value = false;
  } catch (error: any) {
    couldNotInitialize.value = translator.get('app.init_failure') + error.responseJson?.message ?? error.message;
  }
}

/* ----------------------------- LIFECYCLE ----------------------------- */

onMounted(() => {
  fetchCurrentUserAndEnforceLogin();
});
</script>
