<template>
  <div class="form-selectgroup">
    <label v-for="option in options" :key="option.value" class="form-selectgroup-item">
      <input v-model="value" type="radio" name="icons" :value="option.value" class="form-selectgroup-input" />
      <span class="form-selectgroup-label">
        <component :is="option.icon" stroke-width="1" :size="18"></component>
        <Transition name="form-mode"
          ><span v-show="option.value !== value" class="ms-1">{{ option.label }}</span></Transition
        >
      </span>
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

// icons
import { IconWand, IconForms } from '@tabler/icons-vue';
import translator from '@/locale/translator';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: { type: String, required: true },
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const options = [
  { value: 'wizard', label: translator.get('orders.create.wizard'), icon: IconWand },
  { value: 'form', label: translator.get('orders.create.full_form'), icon: IconForms },
];
</script>

<style scoped>
.form-mode-enter-active {
  transition: opacity 0.3s ease-out;
  transition-delay: 0.3s;
}

.form-mode-leave-active {
  transition: opacity 0.3s ease-in;
}

.form-mode-enter-from,
.form-mode-leave-to {
  opacity: 0;
}
</style>
