<template>
  <div>
    <HeaderNavbar>
      <LiPageHeader
        :title="rfObject?.firstname || 'Companies'"
        :subtitle="$t('settings.companies.subtitle')"
        :has-filters="false"
        :route-back-to="{ name: 'settings.accounts' }"
      >
      </LiPageHeader>
    </HeaderNavbar>
    <div class="container-lead">
      <div class="card" v-if="rfObject">
        <div class="row g-0">
          <div class="col-12 col-md-3 border-end">
            <div class="card-body">
              <h4 class="subheader">{{ $t('company.details') }}</h4>
              <div class="list-group list-group-transparent">
                <a
                  v-for="tab in availableTabs"
                  :key="tab.key"
                  href="#"
                  @click.prevent="currentTab = tab.key"
                  class="list-group-item list-group-item-action d-flex align-items-center"
                  :class="{ active: currentTab === tab.key }"
                  >{{ tab.label }}</a
                >
              </div>
            </div>
          </div>
          <div class="col-12 col-md-9 d-flex flex-column">
            <div class="card-body" v-if="currentTab === 'profile'">
              <h2 class="mb-4">{{ $t('account.profile') }}</h2>
              <h3 class="card-title">{{ $t('account.profile.personal_details') }}</h3>
              <div class="row g-3">
                <div class="col-md-4">
                  <FormInput
                    v-model="rfObject.name"
                    :label="$t('company.details.name')"
                    :errors="rfFunctions.errors('name')"
                  ></FormInput>
                </div>
              </div>
              <div class="row g-3">
                <div class="col-md-4 pt-3">
                  <FormInput
                    v-model="rfObject.homepage"
                    :label="$t('company.details.homepage')"
                    :errors="rfFunctions.errors('homepage')"
                  ></FormInput>
                </div>
              </div>
              <!--  address part -->
              <h3 class="card-title mt-4">{{ $t('company.details.address') }}</h3>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <FormInput
                    v-model="rfObject.address_street"
                    :label="$t('company.details.address_street')"
                    :errors="rfFunctions.errors('address_street')"
                  ></FormInput>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <FormInput
                    v-model="rfObject.address_city"
                    :label="$t('company.details.address_city')"
                    :errors="rfFunctions.errors('address_city')"
                  ></FormInput>
                </div>

                <div class="col-md-6 mb-3">
                  <FormInput
                    v-model="rfObject.address_zip"
                    :label="$t('company.details.address_zip')"
                    :errors="rfFunctions.errors('address_zip')"
                  ></FormInput>
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <label class="form-label">{{ $t('company.details.address_country') }}</label>
                <li-autocomplete
                  :icon="null"
                  :search="searchCountries"
                  v-model="rfObject.address_country"
                  placeholder="Country"
                  :debounceTime="500"
                  :errors="rfFunctions.errors('address_country')"
                ></li-autocomplete>
              </div>
            </div>

            <div v-if="currentTab === 'images'">
              <div class="card-body">
                <h2 class="mb-4">{{ $t('company.images.title') }}</h2>

                <div>
                  <!-- company logo -->
                  <div class="">
                    <h3 class="">{{ $t('company.images.logo.title') }}</h3>
                    <p class="text-muted">{{ $t('company.images.logo.subtitle') }}</p>
                  </div>

                  <div class="row align-items-end">
                    <DropFiles
                      :key="companyImages.companyLogo.length"
                      class="logo-dropzone col-3"
                      v-model="companyImages.companyLogo"
                      ref="logoDropzone"
                      @input="
                        (imgObj) => {
                          if (rfObject) {
                            rfObject.logo_image_id = companyImages.companyLogo[0]?.id.toString() || null;
                          }
                        }
                      "
                    />
                    <div class="col-8">
                      <button class="btn" @click="openLogoDropzone">
                        {{ companyImages.companyLogo?.length ? 'Change' : 'Upload' }} logo...
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <!-- company media -->
                <div class="pb-4">
                  <div class="">
                    <h3 class="">{{ $t('company.images.media.title') }}</h3>
                    <p class="text-muted">{{ $t('company.images.media.subtitle') }}</p>
                  </div>

                  <div class="">
                    <DropFiles
                      :allowSelection="allowSelection"
                      class="row flex-wrap g-2"
                      v-model="companyImages.images"
                      :media-count="5"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer bg-transparent mt-auto">
              <div class="btn-list justify-content-end">
                <LoadingButton @press="rfFunctions.save(rfObject)" class="btn btn-primary" :disabled="!canSave">
                  {{ $t('account.profile.save') }} <IconCheck :size="17" class="ms-2"></IconCheck>
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { FormInput, LoadingButton, PageHeader as LiPageHeader } from '@prospective/lithium';
import { Autocomplete as LiAutocomplete } from '@prospective/lithium';
import HeaderNavbar from '../header/HeaderNavbar.vue';
import { useRemoteForm, remoteFormOptions } from '@/form/remote';
import type { Company } from '@/types/types';
import { IconCheck } from '@tabler/icons-vue';
import translator from '@/locale/translator';
import { fetchCompany, fetchCompanyImages } from '@/api/company';
import { fetchAutocompleteFields } from '@/api/order';

import DropFiles from '@/components/common/DropZone/index.vue';

const route = useRoute();
const companyId = ref(route.params.id);

const newPassword = ref('');
const newPasswordRepeat = ref('');
const companyImages = ref<{ companyLogo: any[]; images: any[] }>({
  companyLogo: [],
  images: [],
});
const allowSelection = ref(false);
const companyData = ref<Company | {}>({});
const rfOptions = remoteFormOptions('/v1/account/companies/{id}');
rfOptions.createObject = () => {
  return {
    name: '',
    homepage: '',
    email: '',
    address_street: '',
    address_city: '',
    address_zip: '',
    address_country: '',
  };
};
rfOptions.prepareDataForSave = (data) => {
  if (newPassword.value.length > 0) {
    data.password = newPassword.value;
  }

  return data;
};
const { rfObject, rfFunctions } = useRemoteForm<Company>(rfOptions);

const currentTab = ref('profile');
const availableTabs = [
  { key: 'profile', label: translator.get('account.profile') },
  { key: 'images', label: translator.get('company.images') },
];

onMounted(async () => {
  searchCountries();
  if (companyId.value && companyId.value === '+') {
    companyData.value = rfObject;
    companyImages.value.companyLogo = rfObject?.value?.logo_image_id ? [rfObject?.value?.logo_image_id] : [];
    companyImages.value.images = rfObject?.value?.images || [];
  } else if (companyId.value) {
    try {
      const { data } = await fetchCompany(Number(companyId.value), { resolve: 'logo_uploaded_by,logo_image' });
      companyData.value = data;
      if (data.logo_image_id && data.logo_image?.data) {
        companyImages.value.companyLogo = [data.logo_image.data];
      }
      const res = await fetchCompanyImages(Number(companyId.value), { type: 'image' });
      companyImages.value.images = Array.isArray(res.data) ? res.data : [];
      // if (rfFunctions.load) {
      //   await rfFunctions.load(Number(companyId.value));
      // }
    } catch (error) {
      console.error('Failed to fetch the company data');
    }
  }
});

const canSave = computed(() => {
  if (newPassword.value.length > 0) {
    return newPassword.value === newPasswordRepeat.value;
  }

  return true;
});

const searchCountries = () => {
  return fetchAutocompleteFields('workplace_country', { q: rfObject.value?.address_country || '' });
};

const logoDropzone = ref();
const openLogoDropzone = () => {
  logoDropzone.value.openInput();
};
</script>

<style lang="scss" scoped>
.logo-dropzone {
  padding-left: 0;
}
.logo-dropzone :deep(.dropzone) {
  background-color: var(--tblr-gray-100);
  border-color: var(--tblr-gray-100);
}
</style>
