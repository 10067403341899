<template>
  <div v-if="featureStore.featureOrderManagment">
    <HeaderNavbar :ms-auto="false">
      <LiPageHeader
        :title="pageTitle"
        :subtitle="$t('nav.orders')"
        :has-filters="false"
        :route-back-to="{ name: 'orders.list' }"
      >
      </LiPageHeader>

      <div class="ms-auto me-3 d-none d-md-block">
        <FormModeToggle v-if="!orderId" v-model="currentFormMode"></FormModeToggle>
      </div>
    </HeaderNavbar>

    <!-- <div class="container-lead">
      <li-page-header
        :title="pageTitle"
        subtitle="Orders"
        :has-filters="false"
        :route-back-to="{ name: 'orders.list' }"
      >
      </li-page-header>
    </div> -->

    <div class="container-lead">
      <FormWizard
        v-if="currentFormMode === 'wizard'"
        :errors="errors"
        :default-campaign-length="defaultCampaignDuration"
        @exit-wizard="currentFormMode = 'form'"
        ref="wizardRef"
      />
      <form v-else-if="!data.loading" class="form col-12 col-lg-9">
        <div>
          <div class="card card-md mb-3">
            <div class="card-body row">
              <div class="mb-3 col-9 col-xl-3 order-first order-xl-0">
                <LiFormInput
                  v-model="order.provider_job_id"
                  :label="$t('orders.create.form.ref_number')"
                  :errors="errors?.provider_job_id"
                  :help="$t('orders.create.form.ref_number_help')"
                >
                </LiFormInput>
              </div>

              <div class="mb-3 col-12 col-xl-7">
                <LiFormInput
                  v-model="order.directlink"
                  :label="$t('orders.wizard.steps.url')"
                  :is-required="true"
                  :errors="errors?.directlink"
                  placeholder="https://jobs.mycomaany.com/job/1234"
                  :hint="$t('orders.wizard.intro.url_help')"
                >
                </LiFormInput>
              </div>
              <div class="col-3 col-xl-auto order-first order-xl-0 mb-3">
                <label class="form-label">{{ $t('orders.wizard.media.company_logo') }}</label>
                <div class="avatar avatar-lg p-1">
                  <img style="max-height: 100%" :src="companylogo?.proxy.url_thumbnail" />
                </div>
              </div>

              <div class="mb-3 col-12">
                <LiFormInput
                  v-model="order.title"
                  :label="$t('orders.wizard.details.job_title')"
                  :is-required="true"
                  :errors="errors?.title"
                  placeholder="Key Account Manager, Sales..."
                  :hint="$t('orders.wizard.details.job_title_help')"
                >
                </LiFormInput>
              </div>

              <div class="col-12 col-lg-6 mb-3 mb-lg-0">
                <label class="form-label required">{{ $t('orders.wizard.details.company_name') }}</label>
                <CompanySelector
                  v-model:company-name="order.company_name"
                  v-model:company-id="order.company_id"
                  :class="{ 'is-invalid': (errors?.company_name ?? []).length > 0 }"
                ></CompanySelector>
                <LiFormErrorList :errors="errors?.company_name"></LiFormErrorList>
              </div>

              <div class="col-12 col-lg-6">
                <FormDatePick
                  v-model:startdate="order.startdate"
                  v-model:enddate="order.enddate"
                  :default-campaign-length="defaultCampaignDuration"
                ></FormDatePick>
                <LiFormErrorList :errors="errors?.startdate"></LiFormErrorList>
                <LiFormErrorList :errors="errors?.enddate"></LiFormErrorList>
              </div>
            </div>
          </div>

          <!-- Budget -->
          <div class="card card-md mb-3">
            <div class="card-body">
              <!-- <li-form-input
                v-model.number="order.budget_gross_total"
                type="currency"
                :locale="defaultLocale"
                :currency="computedCurrency"
                label="Campaign Budget"
                :is-required="true"
                class="col-12"
                :errors="errors?.budget_gross_total"
              >
              </li-form-input> -->

              <FormBudgetPick
                v-if="order.budget_gross_total !== undefined"
                v-model:budget="order.budget_gross_total"
                :startdate="order.startdate"
                :enddate="order.enddate"
              ></FormBudgetPick>
              <LiFormErrorList :errors="errors?.budget_gross_total"></LiFormErrorList>
            </div>
          </div>

          <div class="card card-md mb-3">
            <div class="card-body row">
              <div class="mb-3 col-6">
                <label class="form-label required">{{ $t('orders.wizard.details.workload_min') }}</label>
                <div class="input-group mb-2">
                  <input
                    v-model="order.workload_min"
                    type="number"
                    class="form-control"
                    :class="{ 'is-invalid': (errors?.workload_min ?? []).length > 0 }"
                    autocomplete="off"
                    placeholder="example: 80"
                  />
                  <span class="input-group-text"> % </span>
                </div>
                <LiFormErrorList :errors="errors?.workload_min"></LiFormErrorList>
              </div>

              <div class="mb-3 col-6">
                <label class="form-label required">{{ $t('orders.wizard.details.workload_max') }}</label>
                <div class="input-group mb-2">
                  <input
                    v-model="order.workload_max"
                    type="number"
                    class="form-control"
                    autocomplete="off"
                    :class="{ 'is-invalid': (errors?.workload_max ?? []).length > 0 }"
                    placeholder="example: 100"
                  />
                  <span class="input-group-text"> % </span>
                </div>
                <LiFormErrorList :errors="errors?.workload_max"></LiFormErrorList>
              </div>

              <LiFormInput
                v-model="order.workplace_location"
                :label="$t('orders.wizard.details.location')"
                :is-required="true"
                class="col-12"
                :errors="errors?.workplace_location"
                placeholder="example: Zurich, Switzerland"
                :hint="$t('orders.wizard.details.location_help')"
              >
              </LiFormInput>
            </div>
          </div>

          <div class="card card-md mb-3">
            <div class="card-body">
              <label class="form-label d-flex gap-2 align-items-center">
                <span>{{ $t('orders.wizard.media.order_images') }}</span>
                <IconPencil class="text-primary" role="button" :size="18" @click="goToWizardMedia" />
              </label>
              <div class="row" v-if="orderImages.images.length">
                <div
                  class="col-4 col-lg-3 my-3"
                  style="max-width: fit-content"
                  :key="img.hash"
                  v-for="img in orderImages.images"
                >
                  <label class="form-imagecheck">
                    <input
                      type="checkbox"
                      class="form-imagecheck-input d-flex justify-content-center align-items-center h-100 mx-auto"
                      :checked="img.selected"
                      @click="img.selected = !img.selected"
                    />
                    <span class="form-imagecheck-figure">
                      <img
                        class="form-imagecheck-image"
                        :src="img.proxy.url_preview"
                        :alt="img.description || undefined"
                        style="max-height: 100px"
                      />
                    </span>
                  </label>
                </div>
              </div>
              <div class="d-flex align-items-center text-muted" v-else>{{ $t('orders.create.form.images_empty') }}</div>
            </div>
          </div>

          <div class="card card-md">
            <div class="card-body row">
              <div class="mb-3 col-12">
                <LiFormInput
                  v-model="order.comment"
                  :value="order.comment"
                  :label="$t('common.comment')"
                  class="col-12"
                  type="textarea"
                  rows="5"
                  :placeholder="$t('orders.wizard.remarks.msg_placeholder')"
                >
                </LiFormInput>
                <span class="fs-5" :class="maxChars > 512 ? 'text-danger' : 'text-muted'">{{ maxCharsString }}</span>
                <LiFormErrorList :errors="errors?.comment"></LiFormErrorList>
              </div>
            </div>
          </div>

          <div class="mt-3 d-flex justify-content-end mb-4">
            <LiLoadingButton class="btn btn-primary ms-auto" @press="saveOrder">
              {{ $t('orders.create.form.save_confirm') }}
              <i class="ti" :class="[saveButtonShowingWarning ? 'ti-alert-triangle' : 'ti-check']" />
            </LiLoadingButton>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, onMounted, ref, provide, unref, computed, nextTick } from 'vue';
import type { Ref } from 'vue';
import { ClientError, request } from '@/api/client';
import { changeOrderImages, getOrderImages } from '@/api/order';
import { useRouter } from 'vue-router';
import { addDays, startOfTomorrow } from 'date-fns';
import { IconPencil } from '@tabler/icons-vue';

import FormModeToggle from './create/FormModeToggle.vue';
import FormWizard from './create/FormWizard.vue';
import CompanySelector from '@/components/company/CompanySelector.vue';

import formatDateTime from '@/filters/dateFormat';
import { scrollToTop } from '@/func/scrollTo';

import FormDatePick from './create/FormDatePick.vue';
import FormBudgetPick from './create/FormBudgetPick.vue';

import type { Order, OrderMedia } from '@/types/types';

import '@vuepic/vue-datepicker/dist/main.css';
import {
  PageHeader as LiPageHeader,
  FormInput as LiFormInput,
  LoadingButton as LiLoadingButton,
  FormErrorList as LiFormErrorList,
} from '@prospective/lithium';
import { useToast } from 'vue-toastification';

import HeaderNavbar from '../header/HeaderNavbar.vue';

import { defaultCampaignDuration, defaultCurrency } from '../../config/app';
import { useCustomerFeaturesStore } from '@/stores/customerFeatures';
import { createEmptyOrderData, type OrderCreationData } from './create/orderCreation';
import translator from '@/locale/translator';
import { useUserStore } from '@/stores/user';

const router = useRouter();
const toast = useToast();
const userStore = useUserStore();
const wizardRef = ref<InstanceType<typeof FormWizard>>();

const featureStore = useCustomerFeaturesStore();

const tomorrowStringified = formatDateTime(startOfTomorrow(), 'yyyy-MM-dd HH:mm:ss');
const endDateStringified = formatDateTime(addDays(startOfTomorrow(), defaultCampaignDuration), 'yyyy-MM-dd HH:mm:ss');

const data = reactive({
  loading: true,
});

/* -------- CHECK EXISTING orderId IN LOCAL_STORAGE WHEN DUPLICATING -------- */
const orderId = window.localStorage.getItem('orderId');
window.localStorage.removeItem('orderId');

const order = ref<Order>({} as Order);
const orderImages = ref<OrderMedia>({ companyLogo: [], images: [] });
const setDefaultCompanyLogo = ref<boolean>(false);
const orderCreation = ref<OrderCreationData>(createEmptyOrderData());

/* -------- USE PROVIDES TO COLLECT DATA FROM WIZARD WITHOUT PROPS -------- */
provide<Ref<Order>>('order', order);
provide<Ref<OrderMedia>>('orderImages', orderImages);
provide<Ref<OrderCreationData>>('orderCreation', orderCreation);
provide<Ref<boolean>>('setDefaultCompanyLogo', setDefaultCompanyLogo);

// set some defaults
order.value.startdate = tomorrowStringified;
order.value.enddate = endDateStringified;
order.value.budget_gross_total = 0;

// wizard is only enabled for new orders
const defaultFormMode = orderId ? 'form' : 'wizard';
const currentFormMode = ref<'form' | 'wizard'>(defaultFormMode);

/**
 * Orders base minimum budget
 */
const minimumTotalBudget = ref<number>(0);

// current page title
const pageTitle = computed(() => {
  if (currentFormMode.value === 'form' && !orderId) {
    return translator.get('orders.create.title_confirm');
  }
  if (orderId) {
    return translator.get('orders.create.title_modify');
  }
  return translator.get('orders.create.title_create');
});

const companylogo = computed(() => {
  const logos = orderImages.value.companyLogo;
  return logos.length ? logos[0] : null;
});

// header size is different for wizard
const headerSizeClass = computed(() => {
  if (currentFormMode.value === 'wizard') {
    return 'col-xl-4 col-lg-6 col-md-8';
  }
  return 'col-lg-9';
});

const maxChars = computed((): number => {
  return order.value.comment ? order.value.comment.length : 0;
});

const maxCharsString = computed((): string => {
  return `${maxChars.value}/512`;
});

onMounted(async () => {
  // User might try to directly enter the URL for various reasons
  if (!featureStore.featureCanCreateEditOrder) {
    toast.warning('Access denied. Missing permissions');
    return router.replace({ name: 'home' });
  }

  if (orderId) {
    const fetchOrder = await request('GET', `/v1/account/order/${orderId}`, {
      resolve: 'company',
    });
    order.value = await fetchOrder?.data;

    orderImages.value = await getOrderImages(orderId);

    // clear the id and reference number, since this is a duplicate
    order.value.id = null;
    order.value.provider_job_id = null;

    // this also applies to the start and enddate
    order.value.startdate = tomorrowStringified;
    order.value.enddate = endDateStringified;
  }
  data.loading = false;
});

// Get the user defined currency when creating an order, or get the order currency, fallback is the default currency
const computedCurrency = computed(() => {
  if (orderId) {
    return unref(order)?.budget_currency ?? defaultCurrency;
  }
  return userStore.currentCustomer?.currency ?? defaultCurrency;
});

provide('computedCurrency', computedCurrency);

const errors = ref({}) as any;

const resetErrors = () => {
  errors.value = {};
};

const saveButtonShowingWarning = ref(false);
function saveButtonDisplayWarning() {
  // @todo this is only required till we switch to LI3
  setTimeout(() => {
    saveButtonShowingWarning.value = true;
    setTimeout(() => {
      saveButtonShowingWarning.value = false;
    }, 5000);
  }, 300);
}

const goToWizardMedia = async () => {
  resetErrors();
  currentFormMode.value = 'wizard';
  await nextTick();
  wizardRef.value?.goToStepByKey('media');
};

const saveOrderImages = async (orderId: number) => {
  const media = orderImages.value;
  if (!media.companyLogo.length && !media.images.length) return;

  try {
    await changeOrderImages(orderId, media, {
      filterSelected: true,
      setDefaultCompanyLogo: setDefaultCompanyLogo.value,
    });
  } catch (error) {
    toast.error('Failed to attach media to the order');
  }
};

const saveOrder = async (stopLoading: () => void) => {
  resetErrors();
  try {
    const res = await request('POST', '/v1/account/order', { control_state: 1 }, order.value, {
      skipDefaultErrorHandler: true,
    });

    if (res.data) {
      // Media should be saved after order is created, before any redirection
      await saveOrderImages(res.data.id);
      if (unref(order).id) {
        toast.success('The order has been updated successfully!');
      } else {
        toast.success('The order has been created successfully!');
        router.push(`/dashboard/orders/detail/${res.data.id}`);
      }
      scrollToTop();
    }
  } catch (e) {
    saveButtonDisplayWarning();
    toast.warning('Invalid or missing data, please check the form and try again.');
    if (e instanceof ClientError) {
      errors.value = e.responseJson.errors;
    }
  } finally {
    stopLoading();
  }
};
</script>
