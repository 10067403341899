import { request } from './client';

export function fetchUrlPreviewImage(url: string = '') {
  return request(
    'POST',
    '/v1/account/media/urlpreview',
    {},
    {
      url,
    }
  );
}
